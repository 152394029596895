import { type HttpEvent, type HttpHandlerFn, type HttpRequest } from '@angular/common/http';
import { inject } from '@angular/core';
import { finalize, tap, type Observable } from 'rxjs';
import { LoadingBarStore } from './loading-bar.store';

export function loadingBarInterceptor(req: HttpRequest<unknown>, next: HttpHandlerFn): Observable<HttpEvent<unknown>> {
  const loadingBarStore = inject(LoadingBarStore);
  let started = false;

  return next(req).pipe(
    tap(() => {
      if (!started) {
        loadingBarStore.start(req);
        started = true;
      }
    }),
    finalize(() => started && loadingBarStore.complete(req))
  );
}
