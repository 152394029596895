import { type HttpRequest } from '@angular/common/http';
import { computed } from '@angular/core';
import { patchState, signalStore, withComputed, withMethods, withState } from '@ngrx/signals';

type LoadingBarState = {
  requests: HttpRequest<unknown>[];
};

const initialState: LoadingBarState = {
  requests: []
};

export const LoadingBarStore = signalStore(
  { providedIn: 'root' },
  withState(initialState),
  withMethods(store => ({
    start: (request: HttpRequest<unknown>) => {
      patchState(store, state => ({ requests: [...state.requests, request] }));
    },
    complete: (request: HttpRequest<unknown>) => {
      patchState(store, state => ({ requests: [...state.requests.filter(r => r !== request)] }));
    }
  })),
  withComputed(store => ({
    requestsPending: computed(() => !!store.requests().length)
  }))
);

export type LoadingBarStore = InstanceType<typeof LoadingBarStore>;
